<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">

    <div v-if="!isSearchAreaShow" class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        Q&A
      </div>
      <!-- //header-title -->

      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-pencil me-3">
          <a href="javascript:" @click="qnaWrite" class="util-actions util-actions-pencil">
            <i class="icon-pencil"></i>
          </a>
        </div>
        <div class="util util-search">
          <a href="javascript:" @click="isSearchAreaShow = true" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>

    <!-- 검색영역  -->
    <div v-else class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" @click="isSearchAreaShow = false" class="util-actions util-actions-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input type="text" v-model="search.inqTitle" placeholder="Q&amp;A검색">
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="javascript:" @click="clickSearch" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk">
    <!-- main-header -->
    <div v-if="!isSearchAreaShow" class="main-header">
      <!-- main-header > header-snb -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li v-for="(item, idx) in inqTyCdDcds" :key="item.cd" class="snb-item" :class="{'is-active' : idx === 0}">
              <button @click="selectClass(item.cd, idx)">{{ item.cdNm }}</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- //main-header -->

    <!-- main-content -->
    <div class="main-content main-component">

      <div v-if="items.length === 0 && searchRslt > 0" class="search-result">
        <!-- result-empty -->
        <div class="result-empty">
          <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
          <p class="text">검색결과가 없어요</p>
        </div>
        <!-- //result-empty -->
      </div>

      <div v-else class="helpdesk-list-container">
        <div class="list-top status">
          <div class="top-column">
            <p class="text text-muted">전체 {{ paging.totalCount }}건</p>
          </div>
        </div>
        <div class="board-list-container">
          <ul class="board-list">
            <li class="board-list-item" v-for="(item, idx) in items" :key="idx">
              <router-link :to="{path:'/help/qna/'+item.comInqSn}" class="board-link"></router-link>
              <div class="list-content">
                <div class="list-content-title">
                  <h5 class="title">
                    <span class="text text-truncate">{{item.inqTitle}}</span>
                    <i v-if="item.newQna === 'Y'" class="icon-new ms-1"></i>
                  </h5>
                </div>
                <div class="list-meta-content">
                  <span class="text fw-bold">{{item.ansYn === 'Y' ? '답변완료' : '처리중'}}</span>
                  <span class="text">{{item.inqTyNm}}</span><span class="text">{{item.lrnerNm}}</span><span
                    class="text">{{getDateFormat('yyyy.MM.dd hh:mm', item.regDt)}} 작성</span>
                </div>
              </div>
            </li>
          </ul>

          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getItems, getPaging, initPaging, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {reactive, watch, ref, computed} from "vue";
import {ACT_GET_HELP_QNA_LIST} from "@/store/modules/help/help";
import CommonPaginationFront from "@/components/CommonPaginationFront";
export default {
  name: 'HelpQna',
  components: {CommonPaginationFront,},
  setup() {
    const isCategoryActive = ref(false);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([{}]);
    const search = reactive({
      inqTitle:'',
      inqTyCdDcd: '',
      inqTyNm: '카테고리'
    })

    const inqTyCdDcds = computed(() =>
        store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2091') === 0));

    const isSearchAreaShow = ref(false);
    const searchRslt = ref(0);

    watch(() => isSearchAreaShow.value, () => {
      if(isSearchAreaShow.value){
        items.value = [];
        paging.value.totalCount = 0;
      }else{
        search.inqTitle = '';
        search.inqTyCdDcd = '';
        searchRslt.value = 0;
        if(Object.keys(route.query).length === 0) getHelpQnaList();
        else pagingFunc();
      }
    })

    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));
    const getHelpQnaList = () => {
      store.dispatch(`help/${ACT_GET_HELP_QNA_LIST}`, {
        inqTitle: search.inqTitle,
        inqTyCdDcd: search.inqTyCdDcd,
        stt: '00',
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
          // 새 게시물인지 날짜로 비교
          items.value.map((x) => {
            let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
            let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
            let date = new Date(diff).getDate();
            if(month + date <= 7) x.newQna = 'Y';
            else x.newQna = 'N';
          })
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.inqTitle) query.inqTitle = search.inqTitle;
      if(search.inqTyCdDcd) query.inqTyCdDcd = search.inqTyCdDcd;
      router.push({query: query});
    };

    const clickSearch = () => {
      searchRslt.value++;
      pagingFunc(1, 10);
    };

    watch(() => route.query, () => {
      if (route.name === 'HelpQna') {
        if (route.query.pageNo) {
          paging.value.pageNo = route.query.pageNo;
        } else {
          paging.value.pageNo = 1;
        }
        if (route.query.pageSize) {
          paging.value.pageSize = route.query.pageSize;
        }
        getHelpQnaList();
      }
    });

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    }

    const setInqCate = (item) => {
      search.inqTyCdDcd = item.cd;
      search.inqTyNm = item.cdNm;
    }

    const selectClass = (dcd, idx) => {
      search.inqTyCdDcd = dcd;
      pagingFunc(1, 10);
      document.querySelectorAll('.snb-item').forEach(x => x.classList.remove('is-active'))
      document.querySelectorAll('.snb-item')[idx].classList.add('is-active');
    };

    const qnaWrite = () => {
      router.push({name: 'HelpQnaWrite'});
    }

    getHelpQnaList();
    inqTyCdDcds.value.unshift({cd: '', cdNm: '전체'})

    return {
      isCategoryActive,
      items,
      paging,
      search,
      inqTyCdDcds,
      isSearchAreaShow,
      searchRslt,
      selectClass,
      pagingFunc,
      clickSearch,
      getDateFormat,
      qnaWrite,
      setInqCate,
    };
  },
};
</script>

<style scoped>

</style>